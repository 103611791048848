<template>
        <transition name="fade">
            <div v-if="visible" class="d-block modal show" tabindex="-1" role="dialog" style="z-index:10">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title"><b>Информация</b></h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="visible = false">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <h5 style="font-weight: 300; text-align: center;">Технологии <i class="fa fa-cubes "></i></h5>
                            <div class="ml-4">
                            <p><b><i class="fa fa-check-circle"></i> FrontEnd</b></p>
                            <ul>
                                <li><a href="https://ru.vuejs.org/">Vue.js</a></li>
                                <li><a href="https://threejs.org/">Three.js</a></li>
                                <li><a href="https://www.chartjs.org/">Chart.js</a></li>
                                <li><a href="https://getbootstrap.com/">Bootstrap</a></li>
                                <li><a href="https://docs.microsoft.com/ru-ru/aspnet/core/introduction-to-aspnet-core?view=aspnetcore-3.1">ASP.NET Core</a></li>
                            </ul>
                            <p><i class="fa fa-check-circle"></i><b> BackEnd</b></p>
                            <ul>
                                <li>Server: <a href="https://dotnet.microsoft.com/">.NET Core</a></li>
                                <li>DB: <a href="https://www.sqlite.org/index.html">SQLite</a></li>
                                <li><a href="https://docs.microsoft.com/ru-ru/ef/core/">EF Core</a></li>
                            </ul>
                            <br />
                            <p><i class="fa fa-telegram"></i> <a class="ml-1" href="https://t.me/alexmelk_Dev">https://t.me/alexmelk_Dev</a></p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="visible = false">ЗАКРЫТЬ</button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>

export default {
  name: 'AboutModal',
  data(){
      return{
          visible: false
      }
  },
  methods:{
      show(){
          this.visible = true;
      }
  },
    mounted: function(){
    this.$root.$on('aboutmodal', this.show);
  }
}
</script>

<style scoped>
.control-img{
    width:200px; 
    height:200px;
}
@media (max-height: 568px) {
  .control-img  {
    width:70px; 
    height:70px;
  }
}
li{
    list-style: decimal;
}
*{
    font-weight: 200;
}
.fa{
    color: #1fbe1f;
    font-size: inherit;
}

.fa-cubes{
    margin-left: 4px;
    color: dodgerblue;
}
.fa-telegram{
    color:#0088CC;
}
</style>
