export class Camera {
    _camera;

    constructor(THREE) {

        this._camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.01, 85);
        this._camera.position.z = 5;
        this._camera.position.y = 0;
        
        return this;
    }
    getCamera() {
        return this._camera;
    }
}