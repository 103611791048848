<template>
        <transition name="fade">
            <div class="left-widget" :class="{'left-widget-default': visible, 'left-widget-min': !visible }">
                <div>
                    <div class="left-widget-header" :class="{'left-widget-header':visible, 'left-widget-header-min':!visible}">
                        <button class="btn search" v-on:click="visible = !visible;">
                            <span class="icon-wrapper">
                                <span class="fa" :class="{'fa-chevron-circle-left':visible,'fa-chevron-circle-right':!visible}"></span>
                            </span>
                        </button>
                    </div>
                    <div v-show="visible" id="left-widget-body">
                        <div class="mt-3">
                            <div class="form-group mb-2 ml-2 mr-2">
                                <div v-if ="searchingResult.length <= 1">
                                    <label for="find"><b>Поиск по адресу:</b></label>
                                    <div class="input-group" style="width:auto;">
                                        <textarea id="find" type="text" class="form-control" 
                                        placeholder="ул. Ленина" 
                                        aria-label="ул. Ленина" 
                                        aria-describedby="find"
                                        v-model="searchingText"
                                        data-toggle="tooltip" 
                                        data-placement="top" style="min-height:60px;"></textarea>
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary btn-primary" type="button" @click="search">НАЙТИ</button>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="form-group">
                                        <label for="searchingResult"><b>Выберите из списка:</b></label>
                                        <select id="searchingResult" class="form-control" v-model="selectedFias" @change="changeAddress">
                                            <option disabled selected value="Не выбрано">Не выбрано</option>
                                            <option v-for="item in searchingResult" v-bind:key="item.ObjectId" v-bind:value="item">{{item.PresentRow}}</option>
                                        </select>
                                    </div>
                                    <button class="btn btn-primary" @click="searchingResult = []">НАЗАД</button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" style="z-index:2">
                                <ol class="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                </ol>
                            <div v-if="enableYaImages">
                                <div class="carousel-inner">
                                    <div class="carousel-item" v-for="img in images" v-bind:key="img" v-bind:class="{active: images.indexOf(img)==0}">
                                        <img class="d-block carousel-img" v-bind:src="img" alt="First slide">
                                    </div>
                                </div>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                </a>
                                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                </div>
                                <div v-else>
                                    <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img class="d-block carousel-img" src="/images/фото1.PNG" alt="First slide">
                                        <div class="carousel-caption d-none d-md-block ">
                                            <h5>Адрес строения</h5>
                                            <p>г. Смоленск, ул. Шевченко, 3</p>
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <img class="d-block carousel-img" src="/images/фото2.PNG" alt="Second slide">
                                        <div class="carousel-caption d-none d-md-block">
                                            <h5>Адрес строения</h5>
                                            <p>г. Смоленск, ул. Шевченко, 3</p>
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <img class="d-block carousel-img" src="/images/фото3.PNG" alt="Third slide">
                                    </div>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                </a>
                                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                </div>
                                </div>

                            </div>
                        </div>
                        <div v-if="isDebug" class="left-widget-footer ml-2 mb-2">
                            <div v-if="selectedObject!=''">
                                <small>Выбран объект: {{selectedObject}}</small>
                            </div>
                            <div v-if="guid!=''">
                                <small>GUID: {{guid}}</small>
                            </div>
                            <div v-if="enableSaveMapping">
                                <button class="btn btn-primary" @click="saveMapping">Сохранить соответствие</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>

export default {
  name: 'LeftWidget',
  props:{
    selectedObject:String,
    isDebug:Boolean,
  },
  data(){
      return{
          visible: true,
          searchingText: "Смоленская область, городской округ город Смоленск, город Смоленск, улица Шевченко, дом 3",
          selectedFias:Object,
          searchingResult:[],
          postalCode:"",
          images:[],
          enableYaImages: false,
          guid:"",
      }
  },
  methods:{
      async search(){
          let arr = await this.fias.getFiasIdFromAddress(this.searchingText);

          if(arr.result){
             this.searchingResult = arr.data;
             this.selectedFias = "Не выбрано";

             if(this.searchingResult.length == 1){
                this.selectedFias = this.searchingResult[0];
                this.changeAddress();
             }
             if(arr.data.length==0){
                this.$emit("showinformation", `Отсутствуют данные по введённому адресу <b>(${this.searchingText})</b>`);
             }

          }
      },
      async getInfo(fiasId){

        let info = await this.fias.getInfoFromFiasId(fiasId);

        if(info.data.Data.length != 0){
            this.postalCode = info.data.Data[0].PostalCode;
            this.guid = info.data.Data[0].GUID;
        }
        else{
            this.$emit("showinformation", `Не выбран дом!`);
        }
        if(this.enableYaImages){
            await this.loadImages();
        }
      },
      async loadImages(){
        let img = await this.yaImages.getUrlFromAddress(this.selectedFias.PresentRow);
        this.images = img.data;
      },
      changeAddress(){
        this.searchingText = this.selectedFias.PresentRow;
        this.searchingResult = [];
        this.enableYaImages = this.searchingText == "Смоленская область, городской округ город Смоленск, город Смоленск, улица Шевченко, дом 3"?false:true;
        this.getInfo(this.selectedFias.ObjectId);
      },
      async saveMapping(){
          await this.settings.saveMapping(this.selectedObject, this.guid, this.selectedFias.PresentRow);
      },
      async objectClicked(objectName){
          this.guid = "";
          this.selectedFias = new Object();

          let info = await this.settings.getInfoFromObjectName(objectName);
          if(!info.result) return;
          this.guid = info.data.guid;
          this.searchingText = info.data.address;
          this.selectedFias.PresentRow = info.data.address;
          this.selectedFias.GUID  = info.data.guid;

          this.enableYaImages = this.searchingText == "Смоленская область, городской округ город Смоленск, город Смоленск, улица Шевченко, дом 3"?false:true;
          await this.loadImages();
      }
  },
  computed:{
      enableSaveMapping(){
          return (this.selectedObject!='') && (this.guid!='');
      }
  },
  mounted: function(){
    this.$root.$on('objectClicked', this.objectClicked);
  }
}
</script>

<style scoped>

        .left-widget {
            position: absolute;
            bottom: 100px;
            top: 100px;
            left: 10px;
            width: 30%;
            min-width: 280px;
            min-height: 600px;
            max-height: 800px;
            border-radius: 5px;
            box-shadow: 0 0 10px 0.5px;
            background-color: #ffffffb8;
        }
        .left-widget-default {
            left: 10px;
            width: 30%;
            min-width: 280px;
            min-height: 350px;
        }
        .left-widget-min {
            width: 46px;
            height: 41px;
            min-height: 0px;
            min-width: 0px;
        }
        .left-widget-header {
            background-color: #8cade8;
            width: 100%;
            height: 41px;
            border-radius: 5px 5px 0px 0px;
        }
        .left-widget-header-min {
            display: flex;
            justify-content: center;
            background-color: #8cade8;
            width: 100%;
            height: 41px;
            border-radius: 5px;
            background-color: white;
        }
        .carousel-caption {
            background-color: #757575;
            opacity: 0.9;
        }
        .carousel-inner {
            background-color: #87929c44;
        }
        @media screen and (max-height: 568px) {
            .carousel-img  {
                max-height: 169px!important;
            }
        }
        .carousel-img {
            max-height: 190px;
            margin:auto;
        }
        .btn.search {
            height: 40px;
        }
        .left-widget-footer {
            position: absolute;
            bottom: 0;
        }
        .carousel.slide{
            margin-top: 50px;
        }
        
    @media screen and (max-width:575px) {
    .left-widget-default {
        width: unset !important;
    }
    .left-widget {
        right: 10px;
    }
    .carousel.slide{
        margin-top: unset;
    }
    }
</style>
