<template>
        <transition name="fade">
            <div v-show="visible" class="modal show" tabindex="-1" role="dialog" style="z-index:10; display:block">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Информация об объекте</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="visible = false">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div v-show="visibleBody">
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="#select-ls">Лицевой счет:</label>
                                            <select class="custom-select" id="select-ls" v-model="selectedLs" v-on:change="updateObjectData()">
                                                <option v-for="ls in lsNumbers" v-bind:key="ls" v-bind:value="ls">{{ls}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="#select-counter">Прибор учета:</label>
                                            <select class="custom-select" id="select-counter" v-model="selectedCounter">
                                                <option v-bind:value="selectedCounter">{{selectedCounter}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h5>Показатели прибора учета</h5>
                                    <span>Последнее обновление: <span>{{lastUpdate}}</span></span>
                                </div>

                                <!-- Навигация вкладки -->
                                <ul class="nav nav-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-toggle="tab" href="#volt" role="tab">Напряжение</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" href="#ampere" role="tab">Сила тока</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" href="#va" role="tab">Потребление</a>
                                    </li>
                                </ul>

                                <!-- Вкладка панели -->
                                <div class="tab-content">
                                    <div class="tab-pane active" id="volt" role="tabpanel">
                                        <canvas id="chartV"></canvas>
                                    </div>
                                    <div class="tab-pane" id="ampere" role="tabpanel">
                                        <canvas id="chartA"></canvas>
                                    </div>
                                    <div class="tab-pane" id="va" role="tabpanel">
                                        <canvas id="chartVA"></canvas>
                                    </div>
                                </div>

                            </div>
                            <div v-show="!visibleBody">
                                <h5>{{infoText}}</h5>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="visible = false">ЗАКРЫТЬ</button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import { Chart, ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController, PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale, TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip} 
from 'chart.js';
Chart.register( ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController, PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale, TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip);

export default {
  name: 'AboutBilding',
  props: {
    serverUrl: String,
  },
  data(){
    return{
      visible: false,
      visibleBody: false,
      infoText: "",
      lsNumbers: [],
      chartsData: {},
      lastUpdate: "00:00",
      selectedLs: "",
      selectedCounter: "",
      chartA: Object,
      chartV: Object,
      chartVA: Object,
    }
  },
  methods:{
     objectClicked: async function (objectName) {

            let infoAboutObject = await this.waapi.getInfoAboutObject(objectName);

            if(!infoAboutObject.result){
                this.visible = false;
                return;
            }

            this.lsNumbers = [];
            this.visible = true;

            if (infoAboutObject.result) {
                this.lsNumbers = infoAboutObject.data;
                this.selectedLs = this.lsNumbers[0];
                this.updateObjectData();
            }
            else{
                this.infoText = infoAboutObject.Data;
            }
            this.visibleBody = infoAboutObject.result;

        },
      updateObjectData: async function(){

            let objectData = await this.waapi.getInfoAboutLs(this.selectedLs);
            if(!objectData.result){
                this.visible = false;
                return;
            }

            let history = objectData.data[0].history;
            let counterNumber = objectData.data[0].ZavodNomer;

            let voltage = [].map.call(history, (item) => { return item["НАПРЯЖЕНИЕ"]});
            let ampere = [].map.call(history, (item) => { return item["СИЛАТОКА"]});
            let av = [].map.call(history, (item) => { return item["ПОТРЕБЛЕНИЕ"] });
            let label = [].map.call(history, (item) => { return item["ЧАС"] });

            this.chartsData.voltage = voltage;
            this.chartsData.ampere = ampere;
            this.chartsData.av = av;
            this.chartsData.label = label;
            this.chartsInit();
            this.lastUpdate = label[label.length - 1]; 
            this.selectedCounter = counterNumber;
    },
    chartsInit: function () {
      let chartData = this.chartsData;
      this.chartVInit(chartData.label, chartData.voltage);
      this.chartAInit(chartData.label, chartData.ampere);
      this.chartVAInit(chartData.label, chartData.av);
    },
    chartAInit: function(labels, array){
    if (this.chartA != Object) {
        this.chartA.destroy();
    }
    const data = {
        labels: labels,
        datasets: [{
            label: 'График изменения тока',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: array,
        }]
    };
    const config = {
        type: 'line',
        data,
        options: {}
    };
    this.chartA = new Chart(
        document.getElementById('chartA'),
        config
    );   
    },
    chartVAInit:function(labels, array){
    if (this.chartVA != Object) {
        this.chartVA.destroy();
      }
    const data = {
        labels: labels,
        datasets: [{
            label: 'График изменения потребления',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: array,
        }]
    };
    const config = {
        type: 'line',
        data,
        options: {}
    };
    this.chartVA = new Chart(
        document.getElementById('chartVA'),
        config
    );
    },
    chartVInit:function(labels, array){
       if (this.chartV != Object) {
        this.chartV.destroy();
    }
    const data = {
        labels: labels,
        datasets: [{
            label: 'График изменения напряжения',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: array,
        }]
    };
    const config = {
        type: 'line',
        data,
        options: {}
    };
    this.chartV = new Chart(
        document.getElementById('chartV'),
        config
    );
  }

  },
  mounted:function(){
    this.$root.$on('objectClicked', this.objectClicked);
  }
}

</script>

<style scoped>

</style>
