export class Api {

    _serverUrl;
    _preloaderOn;
    _preloaderOff;
    _showInformation;

    constructor(serverUrl, showInformation, preloaderOn, preloaderOff){
        this._serverUrl = serverUrl;
        this._preloaderOn = preloaderOn;
        this._preloaderOff = preloaderOff;
        this._showInformation = showInformation;
    }
    
    async get(url){

        this._preloaderOn();
        let answer = [];
        try{
            let response = await fetch(`${this._serverUrl}${url}`);
            if(response.ok){
                answer = await response.json();
                if(answer.showInformation){
                    this._showInformation(answer.informationText);
                }
            }
            else{
                this._showInformation("<b>Ошибка №1</b>(ошибка при обращении к серверу) Повторите запрос позднее.");
            }
        }
        catch(exception){
            if(exception == "TypeError: Failed to fetch"){
                this._showInformation("<b>Ошибка №2</b> (не удалось связаться с сервером) Повторите запрос позднее.");
            }
        }

        await this.delay(500);

        this._preloaderOff();

        return answer;
    }
    async post(url, params){
        this._preloaderOn();
        let answer = [];
        try{
            let response = await fetch(`${this._serverUrl}${url}`, { 
                method:"POST", 
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(params)
            });
            if(response.ok){
                answer = await response.json();
                if(answer.showInformation){
                    this._showInformation(answer.informationText);
                }
            }
            else{
                this._showInformation("<b>Ошибка №3</b>(ошибка при обращении к серверу) Повторите запрос позднее.");
            }
        }
        catch(exception){
            if(exception == "TypeError: Failed to fetch"){
                this._showInformation("<b>Ошибка №4</b> (не удалось связаться с сервером) Повторите запрос позднее.");
            }
            console.log(exception);
        }

        await this.delay(500);

        this._preloaderOff();

        return answer;
    }

    async delay(time){
        return new Promise((resolve) => {
           setTimeout(() => resolve(), time)
        })
    }

}