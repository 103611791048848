import gsap from 'gsap'

export class Animations {
    static _camera;
    static _controls;
    _loader;
    constructor(THREE, camera, controls, loader) {
        Animations._camera = camera;
        Animations._controls = controls;
        this._loader = loader;

        return this;
    }
    getAnimations() {
        return this;
    }
    houseAnimation(model) {
        if (!model.visible) return;
        //начальная позиция анимации
        console.log(model);
        model.rotation.y = 3.14;
        Animations._camera.position.x = 0;
        Animations._camera.position.y = 14.9999999999925;
        Animations._camera.position.z = 0.000014998764309676485;
        //направление просмотра камеры
        let targetY = 0.85;
        let houseWall =  model.getObjectByName("house1");
        let ladder = model.getObjectByName("pasted__latisse");
        ladder.receiveShadow = false;

        let tl = new gsap.timeline({ onUpdate: () => { Animations._controls.target.y = targetY; Animations._controls.update(); } }); //дополнительно направляем камеру в центр объекта с поправкой на высоту //дополнительно направялем камеру в центр объекта с поправкой на высоту
        tl.to(Animations._camera.position, 4, { y: 7 });
        tl.to(Animations._camera.position, 2, { y: 3.5, z: 6.6 });
        tl.to(Animations._camera.position, 2, { x: -1.28, y: 1.03, z: 2.20 });
        tl.to(houseWall, 1, {visible: false});

        this.counterAnimation = this.counterAnimation.bind(model);
        this.wallAnimation = this.wallAnimation.bind(model);

        setInterval(this.counterAnimation, 400)
        setInterval(this.wallAnimation, 200)
    }
    wallAnimation(){
        let position = Animations._controls.object.position;
        let len = Math.sqrt((position.x*position.x)+(position.y*position.y)+(position.z*position.z))
        let wall = this.getObjectByName("house1");
        wall.visible = len > 3.5;
        let insideHouse =  this.getObjectByName("group9");
        let numbers =  this.getObjectByName("typeMesh5");
        insideHouse.visible = !wall.visible
        numbers.visible = insideHouse.visible;
    }
    counterAnimation(){
        for(let i = 1; i < 5; i++)
        {
            for(let j = 1; j < 11; j++){
                let name = `Counter${j}_${i}`;
                let counter = this.getObjectByName(name);
                counter.visible = !counter.visible;
            }
        }
    }
    cloudAnimation(model) {
        if (!model.visible) return;
        let tl = new gsap.timeline({ repeat: 1000 });
        tl.to(model.rotation, 10000, { y: 100 });
    }
    animateAll() {
        this._loader._loadedModels.forEach((item) => {
            item.model.visible = true;
            item.animation(item.model);
        });
    }

}