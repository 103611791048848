<template>
    <footer class="footer d-none">
        <nav class="navbar bg-white p-0 m-0" style="justify-content:center; position:absolute; bottom:0; width:100%; z-index:9;">
            <ul class="p-0 m-0 d-flex flex-row justify-content-center" style="list-style-type:none;">
                <li>
                    <a style="text-decoration:none;" class="li-item btn-outline-dark" v-bind:href="serverUrl+'/Account/Login'">
                        <span class="fa fa-user-circle-o mb-1"></span>
                        <h6>Войти</h6>
                    </a>
                </li>
                <li class="li-item btn-outline-dark" @click="toggleFullScreen()">
                    <span id="fullScrBtn-mobile" class="fa fa-expand mb-1 full-screen"></span>
                    <h6>FullScreen</h6>
                </li>
            </ul>
        </nav>
    </footer>
</template>

<script>
export default {
  name: 'Footer',
  props:{
    serverUrl:String,
  },
  methods:{
      toggleFullScreen(){
        this.$emit("togglefs");
      }
  }
}
</script>

<style scoped>

@media screen and (max-width:575px) {
    .footer{
        display:block!important;
    }
}

</style>
