<template>
    <header id="header" style="z-index:9; position:absolute; width:100%;" class="mobile-nav">
        <nav class="navbar navbar-expand-sm navbar-toggleable-sm" style="justify-content:center;">
            <div class="mobile-nav" style="display:flex; background-color:white; border-radius:20px; box-shadow:0 0 10px rgba(0,0,0,0.5);">
                <div class="col" style="display:flex; justify-content:center;">
                    <a class="navbar-brand" href="#">
                        <div style="width:175px;">
                            <img src="/images/logo-loading.svg" style="width:45px" alt="loading_logo" />
                            <img src="/images/logo_without_spinn.png" alt="Atomsbt logo" style=" width: 120px; margin-left: 5px;">
                        </div>
                    </a>
                </div>
                <div class="navbar-collapse collapse d-sm-inline-flex">
                    <div class="col">
                        <ul class="navbar-nav flex-grow-1">
                            <li class="nav-item">
                                <a class="nav-link text-dark" href="#">Интерактивная 3D-карта</a>
                            </li>
                            <li class="nav-item" style="display:flex; justify-content:flex-start;align-content:center; flex-wrap:wrap;">
                                <a class="nav-link text-dark" @click="infoModal()">Инфо</a>
                            </li>
                            <li class="nav-item" style="display:flex; justify-content:flex-start;align-content:center; flex-wrap:wrap;">
                                <a class="nav-link text-dark" v-bind:href="serverUrl+'/Account/Login'">Войти</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col d-flex justify-content-end">
                        <button class="btn btn-primary" @click="toggleFullScreen()"><i class="fa fa-expand full-screen"></i></button>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
  name: 'Header',
  props:{
    serverUrl:String
  },
  methods:{
    toggleFullScreen(){
        this.$emit("togglefs");
    },
    infoModal(){
        console.log("aboutmodal");
        this.$emit("aboutmodal");
        this.$root.$emit("aboutmodal");
    }
  }
}
</script>

<style scoped>

/*mobile nav*/
@media screen and (max-width:575px) {
    .mobile-nav {
        width: 100%;
        background-color: white !important;
        box-shadow: none !important;
    }
}
.nav-item{
    cursor: pointer;
}
</style>
