<template>
  <div id="three-js" ref="container"></div>
</template>

<script>

import * as THREE from '../build/three.module.js';
import {OrbitControls} from '../js/jsm/controls/OrbitControls.js';
import {GLTFLoader} from '../js/jsm/loaders/GLTFLoader.js';

import { Loader } from "../js/graphics/loader.js";
import { Animations } from "../js/graphics/animations.js";
import { Controls } from "../js/graphics/controls.js";
import { Renderer } from "../js/graphics/renderer.js";
import { Scene } from "../js/graphics/scene.js";
import { Camera } from "../js/graphics/camera.js";
import { Lights } from "../js/graphics/lights.js";

//увеличивает производительность
var renderer;
var loader;
var animations;
var scene;
var camera;
var controls;
var lights;

export default {
  data(){
    return{
      models:[],
      configs:Object,
      isDefault: true,
    }
  },
  watch:{
    models(){
      this.$emit("modelsadded", this.models);
    }
  },
  methods:{
  initGraphics: async function(){
    this.configs = await this.settings.configs();

    const cameraModule = new Camera(THREE);
    camera = cameraModule.getCamera();

    const sceneModule = new Scene(THREE, this.configs);
    scene = sceneModule.getScene();

    const rendererModule = new Renderer(THREE, this.$refs.container, scene, camera);
    renderer = rendererModule.getRenderer();

    const controlsModule = new Controls(THREE, OrbitControls, this, scene, camera, this.$refs.container, this.configs);
    controls = controlsModule.getControls();
    controlsModule.uiControls();

    const loaderModule = new Loader(THREE, GLTFLoader, this, scene, camera, controls);
    loader = loaderModule.getLoader();

    const animationsModule = new Animations(THREE, camera, controls, loader);
    animations = animationsModule.getAnimations();

    const lightsModule = new Lights(THREE, scene);
    lights = lightsModule.getLights();
    lights.addAmbientLight();
    lights.addDirectionalLight();

    await this.loadModels(this.configs);
    await this.delay(2000);

    this.containerSizeFix();

    this.$emit("allmodelloaded");

    animations.animateAll();

    function loop () {
        renderer.render(scene, camera);
        requestAnimationFrame(loop);
    }

    loop();
  },
  loadModels: async function(configs){
    await loader.load("../models/city.glb", 5, 0, 0, 0, (model) => { animations.houseAnimation(model) }, false, true)
    //await loader.load("../models/pointer.glb", 1, 0, 0, 0, () => { }, false, false)

    
    if(configs.result){this.isDefault = false;}

    let loadSky = this.isDefault? 0: Number(configs.data.loadSky);
    if(loadSky){
      await loader.load("../models/cloud.glb", 0.85, 0, 0, 0, (model) => { animations.cloudAnimation(model) }, false, false);
    }
  },
  containerSizeFix: function(){
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  },
  onResize: function(self){
      window.onresize = function () {
        self.containerSizeFix();
    }
  },
  delay: async function(time){
          return new Promise((resolve) => {
        setTimeout(() => resolve(), time)
    });
  },
  objectClicked: function(name){
    this.$root.$emit("objectClicked", name);
  },
  },
  created: function(){
      this.initGraphics(this);
      this.onResize(this);
  },
  mounted: function(){
  }
}

</script>

<style>
#three-js {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    cursor:pointer;
}
*::-webkit-scrollbar-thumb {
    background-color: rgb(169 169 169)!important;
}
</style>
