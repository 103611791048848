import {Api} from "./api.js"
export class Fias extends Api{
    
    async getFiasIdFromAddress(address){
        let infoAboutObject = await this.get(`/Home/GetFiasIdFromAddress?address=${address}`);
        return infoAboutObject;
    }
    async getInfoFromFiasId(fiasId){
        let infoAboutLs = await this.get(`/Home/GetInfoFromFiasId?fiasId=${fiasId}`);
        return infoAboutLs;
    }

}