import {Api} from "./api.js"
export class Waapi extends Api{
    
    async getInfoAboutObject(objectName){
        let infoAboutObject = await this.get(`/Home/GetInfoAboutObject?objectName=${objectName}`);
        return infoAboutObject;
    }
    async getInfoAboutLs(selectedLs){
        let infoAboutLs = await this.get(`/Home/GetInfoFromServer?ls=${selectedLs}`);
        return infoAboutLs;
    }

}