<template>
        <transition name="fade">
            <div v-if="visible" class="d-block modal show" tabindex="-1" role="dialog" style="z-index:10">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Как использовать?</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="visible = false">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>Управление:</p>
                            <div class="d-flex justify-content-center">
                                <img class="control-img" src="images/control.png" alt="controls pic">
                            </div>
                            <br />
                            <p>Управление камерой осуществляется при помощи мыши: </p>
                            <ul>
                                <li>Вращение: удержание ЛКМ и перемещение</li>
                                <li>Перемещение: удержание ПКМ и перемещение</li>
                                <li>Скролл</li>
                                <li>Выбор здания по нажатию на него</li>
                            </ul>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="visible = false">ЗАКРЫТЬ</button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>

export default {
  name: 'Info',
  data(){
      return{
          visible: true
      }
  }
}
</script>

<style scoped>
.control-img{
    width:200px; 
    height:200px;
}
@media (max-height: 568px) {
  .control-img  {
    width:70px; 
    height:70px;
  }
}
</style>
