export class Renderer {
    _renderer;
    _scene;
    _camera;
    _loop = this.loop;

    constructor(THREE, domContainer, scene, camera) {

        this._scene = scene;
        this._camera = camera;
        this._renderer = new THREE.WebGLRenderer({ antialias: true });
        this._renderer.setSize(domContainer.clientWidth, domContainer.clientHeight);
        this._renderer.shadowMap.enabled = true;
        this._renderer.setPixelRatio(window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio);
        domContainer.appendChild(this._renderer.domElement);

        return this;
    }
    getRenderer() {
        return this._renderer;
    }
}