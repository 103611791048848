<template>
        <transition name="fade">
        <div class="is-not-available-page">
            <div class="is-not-available-page_card">
                <div class="start-page-container_card-body">
                    <div style="min-width:238px;">
                        <img src="images/logo-loading.svg" class="logo-loading" alt="loading_logo" />
                        <img src="images/logo_without_spinn.png" class="logo-loading_without-spinn" alt="Atomsbt logo">
                    </div>
                    <div class="mt-5">
                        <h3>Ваше устройство не поддерживается.</h3>
                    </div>
                </div>
            </div>
            <div class="version">Версия: {{version}} </div>
        </div>
        </transition>
</template>

<script>
export default {
  name: 'IsNotAvailable',
    props: {
        version: String,
    },
}
</script>

<style scoped>
.is-not-available-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    background: white;
    }
.is-not-available-page_card {
    display: flex;
    justify-content: center;
    text-align: center
}
.is-not-available-page_card-body {
    top: 250px;
}
</style>
