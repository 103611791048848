<template>
    <div class="scale-container">
        <button class="btn btn-light btn-sm" v-on:click="zoomIn">+</button>
        <div class="scale-devider"></div>
        <button class="btn btn-light btn-sm" v-on:click="zoomOut">-</button>
    </div>
</template>

<script>

export default {
  name: 'MapScale',
  props:{
      mapContainer:Object,
  },
  methods:{
        zoomIn: function () {
            this.mouseZoom(-1);
        },
        zoomOut: function () {
            this.mouseZoom(1);
        },
        mouseZoom: function (direction) {
            let container = this.mapContainer.$el;
            let evt = document.createEvent('MouseEvents');
            evt.initEvent('wheel');
            evt.deltaY = direction;
            container.dispatchEvent(evt);
        },
  }
}
</script>

<style scoped>
 .scale-container {
     display: flex;
     justify-content: center;
     flex-direction: column;
     position: absolute;
     top: 50%;
     right: 5px;
     height: 63px;
     width: 30px;
     background: white;
     border-radius: 5px;
     box-shadow: 0 0 10px 0.5px;
 }
 .scale-container button {
     width: 100%;
     height: 100%;
 }
 .scale-devider {
     width: 100%;
     height: 1px;
     padding-top: 0.5px;
     padding-bottom: 0.5px;
     background: #bcbcbc;
 }
</style>
