import {Api} from "./api.js"
export class Settings extends Api{
    
    async saveMapping(objectName, guid, address){
        let saveMapping = await this.post(`/Home/SaveMapping`, {objectName: objectName, guid: guid, address:address});
        return saveMapping;
    }

    async getInfoFromObjectName(objectName){
        let info = await this.get(`/Home/GetInfoFromObjectName?objectName=${objectName}`);
        return info;
    }

    async ping(){
        await this.get(`/Home/Ping`);
    }
    async configs(){
       var res =  await this.get(`/Home/GetConfigs`);
       if(res.result){
       var buf = [];
       res.data.forEach(element => {
           buf[element["key"]] = element.value;
       });
       res.data = buf;
    }

       return res;
    }

}