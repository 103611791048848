export class Scene {
    _scene;
    _isDefault = true;
    constructor(THREE, configs) {

        if(configs.result){this._isDefault = false;}

        const backgroundScene = this._isDefault? "#6997D3": configs.data.backgroundColor;
        const backgroundFog = this._isDefault? "#ffffff": configs.data.fogColor;
        const showFog = this._isDefault? 1: Number(configs.data.showFog);

        this._scene = new THREE.Scene();
        this._scene.background = new THREE.Color(backgroundScene);

        if(showFog){
            this._scene.fog = new THREE.Fog(backgroundFog, 0.9, 45);
        }
        return this;
    }

    getScene() {
        return this._scene;
    }
}