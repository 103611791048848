<template>
  <div id="app">
    <Header @togglefs="toggleFullScreen" @aboutmodal="aboutModal" :serverUrl="serverUrl"/>

    <!-- Окно загрузки приложения -->
    <Loading v-if="loading" :models="models" :version="version"/>
    <!-- Контейнер карты -->
    <Map v-if="map" @modelsadded="modelsAdded" @allmodelloaded="allModelLoaded" ref="mapContainer"/>
    <MapScale :mapContainer="$refs.mapContainer"/>
    <!-- Левый виджет -->
    <LeftWidget v-show="left_widget" @showinformation="showInformation" :selectedObject="selectedObject" :isDebug="isDebug"/>
    <!-- Как использовать -->
    <Info v-if="info"/>
    <Preloader v-show="preloader"/>
    <!-- Устройство не поддерживается -->
    <IsNotAvailable v-if="is_not_available" :version="version"/>
    <!-- Информация о здании -->
    <AboutBilding :preloader="preloader" :serverUrl="serverUrl" @preloaderon="preloaderOn" @preloaderoff="preloaderOff" @showinformation="showInformation"/>
    <InformationModal :filterClones="filterClones"/>
    <AboutModal />
    <Footer @togglefs="toggleFullScreen" :serverUrl="serverUrl"/>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'font-awesome/css/font-awesome.min.css'
import 'font-awesome/fonts/fontawesome-webfont.svg'

import Vue from 'vue'
import Preloader from './components/Preloader.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Loading from './components/Loading.vue'
import Map from './components/Map.vue'
import LeftWidget from './components/LeftWidget.vue'
import AboutBilding from './components/AboutBilding.vue'
import Info from './components/Info.vue'
import MapScale from './components/MapScale.vue'
import IsNotAvailable from './components/IsNotAvailable.vue'
import InformationModal from './components/InformationModal.vue'
import { Waapi } from "./js/request/waapi.js";
import { Fias } from "./js/request/fias.js";
import { YandexImages } from "./js/request/yandexImages.js";
import { Settings } from "./js/request/settings.js";
import AboutModal from "./components/AboutModal.vue"

export default {
  name: 'App',
  components: {
    Preloader,
    Header,
    Footer,
    Loading,
    Map,
    LeftWidget,
    AboutBilding,
    Info,
    MapScale,
    IsNotAvailable,
    InformationModal,
    AboutModal,
  },
  
  data(){
    return{
      preloader:false,
      loading:true,
      map:true,
      left_widget: false,
      info:false,
      is_not_available: false,
      informationModal:false,
      inforamtionText:"",
      filterClones:true,
      serverUrl:"",
      selectedObject:"",
      models:[],
      version: "0.31",
      isDebug: false,
    }
  },
  methods:{
    preloaderOn: function(){
      this.preloader = true;
    },
    preloaderOff: function(){
      this.preloader = false;
    },
    showInformation: function(informationText){
      this.$root.$emit("showinformation", informationText);
    },
    modelsAdded(models){
      this.models = models;
    },
    allModelLoaded(){
      this.loading = false;
      setTimeout(() => { this.left_widget = true }, 8000);
      setTimeout(() => { this.info = true }, 9000);
    },
      toggleFullScreen:function(){
        if (!document.fullscreen) {
            document.body.requestFullscreen();

            let list = document.querySelectorAll(".full-screen");
            for (let i = 0; i < list.length; i++) {
                list[i].classList.add('fa-compress');
            }
        }
        else {
            document.exitFullscreen();
            let list = document.querySelectorAll(".full-screen");
            for (let i = 0; i < list.length; i++) {
                list[i].classList.remove('fa-compress');
            }
        }
      },
      windowCheck() {
        if ((window.innerWidth < 320)||(window.innerHeight < 568)) {
            this.loading = false;
            this.is_not_available = true;
        }
      },
      objectClicked(name){
        console.log(name);
        this.selectedObject = name;
      }
  },
  created: async function(){
    this.$root.$on('objectClicked', this.objectClicked);

    this.windowCheck();

    this.serverUrl =  this.isDebug ? "http://localhost:5000" : "https://map-backend.ddns.net";
    Vue.prototype.waapi = new Waapi(this.serverUrl, this.showInformation, this.preloaderOn, this.preloaderOff);
    Vue.prototype.fias = new Fias(this.serverUrl, this.showInformation, this.preloaderOn, this.preloaderOff);
    Vue.prototype.yaImages = new YandexImages(this.serverUrl, this.showInformation, this.preloaderOn, this.preloaderOff);
    Vue.prototype.settings = new Settings(this.serverUrl, this.showInformation, this.preloaderOn, this.preloaderOff);


     this.settings.ping();
  },
  mounted: function(){
  }
}
</script>

<style>

  @font-face {
    font-family: 'Rosatom';
    src: url('fonts/Rosatom-Light.eot');
    src: url('fonts/Rosatom-Light.eot?#iefix') format('embedded-opentype'), url('fonts/Rosatom-Light.woff2') format('woff2'), url('fonts/Rosatom-Light.woff') format('woff'), url('fonts/Rosatom-Light.ttf') format('truetype'), url('fonts/Rosatom-Light.svg#Rosatom-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rosatom';
    src: url('fonts/Rosatom-Italic.eot');
    src: url('fonts/Rosatom-Italic.eot?#iefix') format('embedded-opentype'), url('fonts/Rosatom-Italic.woff2') format('woff2'), url('fonts/Rosatom-Italic.woff') format('woff'), url('fonts/Rosatom-Italic.ttf') format('truetype'), url('fonts/Rosatom-Italic.svg#Rosatom-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rosatom';
    src: url('fonts/Rosatom-Regular.eot');
    src: url('fonts/Rosatom-Regular.eot?#iefix') format('embedded-opentype'), url('fonts/Rosatom-Regular.woff2') format('woff2'), url('fonts/Rosatom-Regular.woff') format('woff'), url('fonts/Rosatom-Regular.ttf') format('truetype'), url('fonts/Rosatom-Regular.svg#Rosatom-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rosatom';
    src: url('fonts/Rosatom-Bold.eot');
    src: url('fonts/Rosatom-Bold.eot?#iefix') format('embedded-opentype'), url('fonts/Rosatom-Bold.woff2') format('woff2'), url('fonts/Rosatom-Bold.woff') format('woff'), url('fonts/Rosatom-Bold.ttf') format('truetype'), url('fonts/Rosatom-Bold.svg#Rosatom-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

#app {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 568px;
  min-width: 320px; 
  overflow: hidden;
}
@media (min-width: 768px) {
  #app  {
    font-size: 16px;
    font-weight: 200;
  }
}

body{
  font-family: 'Rosatom';
  font-weight: 200;
  line-height: 1.5;
  font-size: 14px;
}
.li-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: 7px;
    border-radius: 5px;
    padding: 5px;
    width: 100px;
    box-shadow: 0 0 5px;
}
.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}
.modal {
    background-color: rgb(100, 100, 100, 0.4);
}
.modal-background {
    background-color: rgb(100, 100, 100, 0.4);
}

.fa {
  font-size: x-large;
}
.version {
    text-align: center;
    position: absolute;
    bottom: 5px;
    width: 100%;
}
.field-validation-error {
    color: red;
}
@media screen and (max-width:575px) {
.navbar{
  box-shadow: 0 0 5px;
}
}
label, h5, textarea{
  font-weight: 200;
}

</style>
