import GSAP from 'gsap'

export class Controls {
    _app;
    _orbitControls;
    _timeStartClick;
    _scene;
    _camera;
    _rayCast;
    _showAnimation;
    _isDebug = true;
    _isDefault = true;

    constructor(THREE, OrbitControls, app, scene, camera, domContainer, configs) {
        if(configs.result){this._isDefault = false;}

        let minDistance = this._isDefault? "1": configs.data.cameraMinDist;
        let maxDistance = this._isDefault? "15": configs.data.cameraMaxDist;
        this._showClickAnimation = this._isDefault? 1: Number(configs.data.clickOnObjectAnimation);

        this._app = app;
        this._scene = scene;
        this._camera = camera;

        this._orbitControls = new OrbitControls(camera, domContainer);
        this._orbitControls.enableZoom = true;
        //this._orbitControls.enableDamping = true;
        this._orbitControls.maxPolarAngle = 1.5;
        this._orbitControls.minDistance = minDistance;
        this._orbitControls.maxDistance = maxDistance;
        this._orbitControls.update();

        this._rayCast = new THREE.Raycaster();

        return this;
    }

    getControls() {
        return this._orbitControls;
    }

    uiControls() {
        document.querySelector("#three-js").addEventListener("pointerdown", () => { this._timeStartClick = Date.now(); });
        document.querySelector("#three-js").addEventListener("pointerup", (e) => {
            if (Date.now() - this._timeStartClick < 150) {
                this.clickOnObject(e, this);
            }
        });
    }

    //клик по объекту
    clickOnObject(e, self) {
    if (e.which == 1) { //leftClick

        let mouse = {};
        mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

        self._rayCast.setFromCamera(mouse, this._camera);
        var items = self._rayCast.intersectObjects(this._scene.children, true);
        var wasFind = false;
        items.forEach((item) => {
            if (!wasFind) {
                wasFind = true;
                let value = item.object;
                if (value.parent.type != "Object3D") {
                    value = value.parent.parent;
                }

                if (item.object.type == "Group") {
                    if(this._showClickAnimation){
                        let tl = new GSAP.timeline();
                        let startScale = item.object;
                        tl.to(startScale.scale, 0.5, { z: (startScale.scale.z / 100) * 90, });
                        tl.to(startScale.scale, 0.5, { z: startScale.scale.z });
                    }
                    return;
                }

                if (value.name == "Scene") return;

                let wallIsOpen = !value.getObjectByName("house1")?.visible;
                if(wallIsOpen){
                    self._app.objectClicked(value.name);
                    return;
                }

                if(this._showClickAnimation){
                    let startScale = value.scale;
                    let tl = new GSAP.timeline();
                    tl.to(value.scale, 0.5, { z: (startScale.z / 100) * 90, });
                    tl.to(value.scale, 0.5, { z: startScale.z });
                }

                self._app.objectClicked(value.name);
            }
        })
    }
}
}