export class Lights {
    _scene;
    _three;
    constructor(THREE, scene) {
        this._scene = scene;
        this._three = THREE;
        return this;
    }
    addAmbientLight() {
        //  окружающий свет (равномерно освещает объекты)
        let hlight = new this._three.AmbientLight(0x404040, 1.5);
        this._scene.add(hlight);
    }
    addDirectionalLight() {
        //  солнце
        const color = 0xffa95c;
        const intensity = 1;
        let light = new this._three.DirectionalLight(color, intensity);

        light.position.set(10, 10, 10);
        light.target.position.set(-5, 0, 0);
        
        light.shadowCameraLeft = -20;
        light.shadowCameraRight = 20;
        light.shadowCameraTop = 20;
        light.shadowCameraBottom = -20;
        
        light.castShadow = true;
        light.shadow.bias = -0.0001;
        light.shadow.mapSize.width = 1024 * 3;
        light.shadow.mapSize.height = 1024 * 3;

        this._scene.add(light);
        this._scene.add(light.target);
    }

    getLights() {
        return this;
    }
}