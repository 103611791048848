<template>
 <transition>
    <div class="preloader_wrapper modal">
      <div class="preloader">
        <div class="spinner-border text-primary mb-1" role="status" readonly></div>
        <div style="user-select:none;">Загрузка</div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'preloader',
}
</script>

<style scoped>
.preloader_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; 
  text-align: center;
  cursor:pointer;
}
.preloader{
  z-index: 20;
  background: white;
  padding: 20px;
  border-radius: 12px;
}
</style>
