export class Loader {
    _app;
    _scene;
    _camera;
    _controls;
    _loadedModels;
    _loader;

    constructor(THREE, GLTFLoader, app, scene, camera, controls){
        this._app = app;
        this._scene = scene;
        this._camera = camera;
        this._controls = controls;
        this._loadedModels = [];
        this._loader = new GLTFLoader();
    }
    getLoadedModels() {
        return this._loadedModels;
    }
    getLoader() {
        return this;
    }
    load(modelName, scale, posX, posY, posZ, animate, visible, shadow) {

        let self = this;

        return new Promise((resolve) => {
            this._loader.load(modelName, function (gltf) {

                let model = gltf.scenes[0];
                model.scale.set(scale, scale, scale);

                model.position.x = posX;
                model.position.y = posY;
                model.position.z = posZ;

                if (shadow) {
                    model.traverse(n => {
                        if (n.isMesh) {
                            n.castShadow = true;
                            n.receiveShadow = true;
                            if (n.material.map) n.material.map.anisotropy = 4;
                        }
                    });
                }

                self._scene.add(gltf.scene);

                if (visible) {
                    animate(model);
                }
                let modelDescription = { name: modelName, model: model, animation: animate };
                self._loadedModels.push(modelDescription);
                self._app.models.push({ name: modelName });

                resolve(model);
            })
        })
    }
}

