<!-- Модальное окно информации !-->
<template>
        <transition name="fade">
            <div v-show="visible" class="modal show" tabindex="-1" role="dialog" style="z-index:12; display:block">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title"></h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="closeWindow">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <h5 v-html="informationText"></h5>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="closeWindow">ЗАКРЫТЬ</button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
export default {
  name: 'InformationModal',
  props:{
      filterClones:Boolean
  },
    data(){
        return{
            queue:[],
            visible:false,
        }
    },
  methods:{
      showInformation(informationText){
          let skipPush = false;
          if(this.filterClones){
            if(this.queue.includes(informationText)){
                skipPush = true;
                console.log(informationText,"has been skipped");
            }
          }
          if(!skipPush){
            this.queue.push(informationText);
          }
          this.visible = true;
      },
      closeWindow(){
        if(this.queue.length > 1){
            this.queue.shift();
        }
        else{
            this.queue.shift();
            this.visible = false;
        }
      }
  },
  computed:{
    informationText(){ return this.queue.length > 0 ? this.queue[0] : "";}
  },
  mounted:function(){
      this.$root.$on('showinformation', this.showInformation);
  }
}
</script>

<style>

</style>
