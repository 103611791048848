<!-- Окно загрузки приложения !-->
<template>
        <transition name="fade">
            <div v-if="visible" class="start-page-container">
                <div class="start-page-container_card">
                    <div class="start-page-container_card-body">
                        <div class="logo">
                            <img src="images/logo-loading.svg" class="logo-loading"  alt="loading_logo" />
                            <img src="images/logo_without_spinn.png" class="logo-loading_without-spinn" alt ="Atomsbt logo">
                        </div>
                        <hr />
                        <div class="mt-5">
                            <h2>Интерактивная 3D карта</h2>
                            <br />
                            <h3>Загрузка <span class="loader">{{icons}}</span></h3>
                            <h5 style="font-weight:200;">{{loadedModel}}</h5>
                        </div>
                    </div>
                </div>
                <div class="version">Версия: {{version}} </div>
            </div>

        </transition>
</template>

<script>
export default {
  name: 'Loading',
    props: {
        models: Array,
        version: String,
    },
    data(){
        return{
            visible:true,
            icons:"",
        }
    },
  methods:{
            start_page_icons: function () {
            if (this.icons.length == 3) {
                this.icons = "";
            }
            else {
                this.icons += ".";
            }
        },
            loadStartPageAnimation: function () {
            //анимация загрузки
            setInterval(this.start_page_icons, 500);
        }
  },
  computed:{
      loadedModel(){
          return this.models[this.models.length-1]?.name;
      }
  },
  created: function(){
    this.loadStartPageAnimation();
  }
}
</script>

<style>
        .start-page-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            position: fixed;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            z-index: 10;
            background: white;
        }
        .start-page-container_card {
            display: flex;
            justify-content: center;
            text-align: center;
        }
        .start-page-container_card-body {
            padding: 50px 50px 90px 50px;
            margin: 10px;
            border-radius: 20px;
            border-color: #e8e8e8;
            box-shadow: 0 0 20px;
        }
        .loader {
            position: absolute;
            margin-left: 5px;
        }

        .logo-loading {
    height: 100px;
    width: 100px;
}

.logo-loading_without-spinn {
    width: 225px;
    margin-left: 20px;
}
@media screen and (max-width:575px) {
    .logo-loading {
        height: 67px;
        width: 67px;
    }

    .logo-loading_without-spinn {
        width: 150px;
        margin-left: 20px;
    }
}
</style>
